@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/slick-carousel/slick/slick.css";
@import "../../node_modules/slick-carousel/slick/slick-theme.css";
@import url("https://fonts.googleapis.com/css?family=Special+Elite&display=swap");

@import "./variables";
@import "./bootswatch";

h1,
h2,
h3,
h4,
p,
li,
.navbar-brand {
  font-family: "Special Elite", monospace;
}
.section {
  min-height: 100vh;
  position: relative;
  text-align: center;
  background: transparent no-repeat top left;
  background-size: cover;
  padding: 2rem 0;
  > .container-fluid,
  > .container {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }
}
.section1 {
  background-image: url(./../images/bg1.jpg);
  // color: white;
}
.site-title,
.site-tagline {
  color: white;
}
.section2 {
  background-image: url(./../images/bg2.jpg);
  text-align: initial;
  .section-title {
    text-align: center;
  }
  img {
    max-width: 100%;
  }
}
.section3 {
  background-image: url(./../images/bg3.jpg);
}
.section4 {
  background-image: url(./../images/bg4.jpg);
}
.section-title {
  margin-bottom: 1rem;
}
.slick-slider {
  margin-bottom: 1rem;
}
.slick-slide {
  padding: 0 1rem;
  position: relative;
  height: 56px;
  > div {
    position: relative;
    height: 56px;
    > img {
      display: inline !important;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      max-height: 56px;
      max-width: 100%;
      width: initial !important;
    }
  }
}
.card-deck {
  margin-bottom: 1rem;
}
.site-footer {
  padding: 1rem 0;
  p {
    margin-bottom: 0;
  }
}
.navbar {
  .site-logo {
    max-height: 56px;
  }
}
